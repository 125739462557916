import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { openModal } from "../services/modal"
import Layout from "../components/layout"
import CutContainer from "../components/cutContainer"
import { grayGradient4 } from "../services/constants"
import styles from "./sportDetail.module.scss"
import globalStyles from "../services/globalStyles.module.scss"
import reusedStyle from "./index.module.scss"
import Button from "../components/button";
import videoPoster from "../images/sports/video-poster.webp"
import pdfIcon from '../images/common/pdf_icon.svg'

import Testimonial from "../components/testimonial";
import Header from "../components/header"
import Footer from "../components/footer"
import Landing from "../components/index/landing";
import sportDetailLandingStyle from "../components/sportDetail/landing.module.scss"
import TrackVisibility from "../components/trackVisibility";
import Context from "../components/contextProvider";
import FeatureTMP from "../components/sportDetail/featureTMP";
import GetStarted from "../components/getStarted"

import basketballVid from "../videos/sports/Basketball.mp4"
import fieldHockeyVid from "../videos/sports/Field_Hockey.mp4"
import floorballVid from "../videos/sports/Floorball.mp4"
import handballVid from "../videos/sports/Handball.mp4"
import iceHockeyVid from "../videos/sports/Ice_Hockey.mp4"
import multisportVid from "../videos/sports/Multisport.mp4"
import soccerVid from "../videos/sports/Soccer.mp4"
import shareVid from "../videos/sports/share_V1.mp4"
import feedbackVid from "../videos/sports/messages_fin_v1.mp4"

import prepareBasketballVid from "../videos/sports/tactics_collection_basket.mp4"
import prepareFloorballVid from "../videos/sports/tactics_collection_florball.mp4"
import prepareSoccerVid from "../videos/sports/tactics_collection_fotbal.mp4"
import prepareHandballVid from "../videos/sports/tactics_collection_hazena.mp4"
import prepareIceHockeyVid from "../videos/sports/tactics_collection_hokej.mp4"
import prepareFieldHockeyVid from "../videos/sports/tactics_collection_pozemni_hokej.mp4"
import prepareTennisVid from "../videos/sports/tactics_collection_tennis.mp4"
import prepareVolleyballVid from "../videos/sports/tactics_collection_volejbal.mp4"

import analyzeBasketballVid from "../videos/sports/tactics_notebook_tablet_basket.mp4"
import analyzeFloorballVid from "../videos/sports/tactics_notebook_tablet_florball.mp4"
import analyzeSoccerVid from "../videos/sports/tactics_notebook_tablet_fotbal.mp4"
import analyzeHandballVid from "../videos/sports/tactics_notebook_tablet_hazena.mp4"
import analyzeIceHockeyVid from "../videos/sports/tactics_notebook_tablet_hokej.mp4"
import analyzeFieldHockeyVid from "../videos/sports/tactics_notebook_tablet_pozemni_hokej.mp4"
import analyzeTennisVid from "../videos/sports/tactics_notebook_tablet_tennis.mp4"
import analyzeVolleyballVid from "../videos/sports/tactics_notebook_tablet_volejball.mp4"

import feedbackBasketballVid from "../videos/sports/messages_bsaket.mp4"
import feedbackFloorballVid from "../videos/sports/messages_florball.mp4"
import feedbackSoccerVid from "../videos/sports/messages_fotball.mp4"
import feedbackHandballVid from "../videos/sports/messages_hazena.mp4"
import feedbackIceHockeyVid from "../videos/sports/messages_hokej.mp4"
import feedbackFieldHockeyVid from "../videos/sports/messages_pozemni_hokej.mp4"
import feedbackTennisVid from "../videos/sports/messages_tennis.mp4"
import feedbackVolleyballVid from "../videos/sports/messages_volejball.mp4"

import { getPdfPerSportAndLanguage } from '../utils/getPdfPerSportAndLanguage'


const sportFiles = {
  ['Basketball']: {
    video: basketballVid,
    youtube: 'https://www.youtube.com/embed/SVB1tKbhyvs',
    analyzeVid: analyzeBasketballVid,
    prepareVid: prepareBasketballVid,
    feedbackVid: feedbackBasketballVid,
  },
  ['Field Hockey']: {
    video: fieldHockeyVid,
    youtube: null,
    analyzeVid: analyzeFieldHockeyVid,
    prepareVid: prepareFieldHockeyVid,
    feedbackVid: feedbackFieldHockeyVid,
  },
  ['Floorball']: {
    video: floorballVid,
    youtube: 'https://www.youtube.com/embed/0SUWXFEZdyY',
    analyzeVid: analyzeFloorballVid,
    prepareVid: prepareFloorballVid,
    feedbackVid: feedbackFloorballVid,
  },
  ['Handball']: {
    video: handballVid,
    youtube: 'https://www.youtube.com/embed/hmiFx6_Z8V8',
    analyzeVid: analyzeHandballVid,
    prepareVid: prepareHandballVid,
    feedbackVid: feedbackHandballVid,
  },
  ['Ice Hockey']: {
    video: iceHockeyVid,
    youtube: null,
    analyzeVid: analyzeIceHockeyVid,
    prepareVid: prepareIceHockeyVid,
    feedbackVid: feedbackIceHockeyVid,
  },
  ['Performance']: {
    video: null,
    youtube: 'https://www.youtube.com/embed/0g5sg1J6CuM',
    analyzeVid: analyzeVolleyballVid,
    prepareVid: prepareFloorballVid,
    feedbackVid: feedbackFloorballVid,
  },
  ['Soccer']: {
    video: soccerVid,
    youtube: 'https://www.youtube.com/embed/rHIwKHI4Ebs',
    analyzeVid: analyzeSoccerVid,
    prepareVid: prepareSoccerVid,
    feedbackVid: feedbackSoccerVid,
  },
  ['Volleyball']: {
    video: null,
    youtube: null,
    analyzeVid: analyzeVolleyballVid,
    prepareVid: prepareVolleyballVid,
    feedbackVid: feedbackVolleyballVid,
  },
  ['Water Polo']: {
    video: null,
    youtube: null,
    analyzeVid: analyzeVolleyballVid,
    prepareVid: prepareFloorballVid,
    feedbackVid: feedbackFloorballVid,
  },
  ['Tennis']: {
    video: null,
    youtube: null,
    analyzeVid: analyzeTennisVid,
    prepareVid: prepareTennisVid,
    feedbackVid: feedbackTennisVid,
  },
  ['Other']: {
    video: null,
    youtube: null,
    analyzeVid: analyzeVolleyballVid,
    prepareVid: prepareFloorballVid,
    feedbackVid: feedbackFloorballVid,
  },
}

/*
 ▄▄▄       ▄▄▄▄    ▄▄▄       ███▄    █ ▓█████▄  ▒█████   ███▄    █     ▄▄▄       ██▓     ██▓        ██░ ██  ▒█████   ██▓███  ▓█████
▒████▄    ▓█████▄ ▒████▄     ██ ▀█   █ ▒██▀ ██▌▒██▒  ██▒ ██ ▀█   █    ▒████▄    ▓██▒    ▓██▒       ▓██░ ██▒▒██▒  ██▒▓██░  ██▒▓█   ▀
▒██  ▀█▄  ▒██▒ ▄██▒██  ▀█▄  ▓██  ▀█ ██▒░██   █▌▒██░  ██▒▓██  ▀█ ██▒   ▒██  ▀█▄  ▒██░    ▒██░       ▒██▀▀██░▒██░  ██▒▓██░ ██▓▒▒███
░██▄▄▄▄██ ▒██░█▀  ░██▄▄▄▄██ ▓██▒  ▐▌██▒░▓█▄   ▌▒██   ██░▓██▒  ▐▌██▒   ░██▄▄▄▄██ ▒██░    ▒██░       ░▓█ ░██ ▒██   ██░▒██▄█▓▒ ▒▒▓█  ▄
 ▓█   ▓██▒░▓█  ▀█▓ ▓█   ▓██▒▒██░   ▓██░░▒████▓ ░ ████▓▒░▒██░   ▓██░    ▓█   ▓██▒░██████▒░██████▒   ░▓█▒░██▓░ ████▓▒░▒██▒ ░  ░░▒████▒
 ▒▒   ▓▒█░░▒▓███▀▒ ▒▒   ▓▒█░░ ▒░   ▒ ▒  ▒▒▓  ▒ ░ ▒░▒░▒░ ░ ▒░   ▒ ▒     ▒▒   ▓▒█░░ ▒░▓  ░░ ▒░▓  ░    ▒ ░░▒░▒░ ▒░▒░▒░ ▒▓▒░ ░  ░░░ ▒░ ░
  ▒   ▒▒ ░▒░▒   ░   ▒   ▒▒ ░░ ░░   ░ ▒░ ░ ▒  ▒   ░ ▒ ▒░ ░ ░░   ░ ▒░     ▒   ▒▒ ░░ ░ ▒  ░░ ░ ▒  ░    ▒ ░▒░ ░  ░ ▒ ▒░ ░▒ ░      ░ ░  ░
  ░   ▒    ░    ░   ░   ▒      ░   ░ ░  ░ ░  ░ ░ ░ ░ ▒     ░   ░ ░      ░   ▒     ░ ░     ░ ░       ░  ░░ ░░ ░ ░ ▒  ░░          ░
      ░  ░ ░            ░  ░         ░    ░        ░ ░           ░          ░  ░    ░  ░    ░  ░    ░  ░  ░    ░ ░              ░  ░
 */

class SportPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMessage: false,
      pricingRoute: "",
      sport: null,
      testimonials: null
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e, set) => {
    e.preventDefault();
    const form = e.target;
    set({
      signUpOpen: true,
    })
    form.reset()
    setTimeout(() => {
      XPSSignup['_email'](form.querySelectorAll('input')[1].value);
      document.querySelectorAll('input#name')[0].focus();
    })
  };

  getSearchParamType() {
    let search;
    if (typeof window !== `undefined`) {
      search = window.location.search;
    } else {
      search = this.props.location.search
    }
    const usp = new URLSearchParams(search);
    return usp.get('type');
  }

  filterTestimonials(edges, sport) {
    return edges
      .filter(t => !!t.node.title && sport.node.frontmatter.testimonials.some(st => st.testimonial === t.node.title.rendered))
      .map(t => t.node)
  }

  componentWillMount() {
    const { sportContent, allWordpressTestimonials } = this.props.data;
    const searchParamType = this.getSearchParamType();

    const sport = searchParamType ? sportContent.edges.find((val) =>  val.node.frontmatter.title.replace(' ', '').toLowerCase() === searchParamType.toLowerCase())
  
     : sportContent.edges[0];

    const pricingRoute = searchParamType === "PersonalTraining" ? "pricingPT" : "pricingTeamSports";
    const testimonials = this.filterTestimonials(allWordpressTestimonials.edges, sport);

    this.setState({
      pricingRoute,
      sport,
      testimonials
    })
  }

  componentWillReceiveProps(newProps) {
    const { sportContent, allWordpressTestimonials } = newProps.data;
    const searchParamType = this.getSearchParamType();

    const sport = searchParamType ? sportContent.edges.find((val) => {
      return val.node.frontmatter.title.replace(' ', '') === searchParamType;
    }) : sportContent.edges[0];
    const pricingRoute = searchParamType === "Personal Training" ? "pricingPT" : "pricingTeamSports";
    const testimonials = this.filterTestimonials(allWordpressTestimonials.edges, sport);
    
    this.setState({
      pricingRoute,
      sport,
      testimonials,
      landingVideoClicked: false,
      hideVideoPoster: false,
    })
  }


  videoPlaying() {
    this.controls = true;
  }


  componentDidMount() {
    let videoPlayer = document.getElementById('videoPlayer');
    try {
      videoPlayer.addEventListener('playing', this.videoPlaying);
      videoPlayer.src = sportVideos[this.state.sport.node.frontmatter.title] || multisportVid

    } catch (_) { }
  }

  componentWillUnmount() {
    document.getElementById('videoPlayer').removeEventListener('playing', this.videoPlaying);
  }

  posterClick(){
    var videoPlayer = document.getElementById('videoPlayer');
    this.setState({landingVideoClicked: true});
    videoPlayer.play();
    setTimeout(() => {
      this.setState({hideVideoPoster: true})
    }, 1000);
  }

  render() {
    const { texts: { _ }, statisticsImg, headerImgs } = this.props.data;
    const { showMessage, sport, testimonials, landingVideoClicked } = this.state;
    // remove this when all videos will be on vimeo
    // ----
    sport.node.frontmatter.sections[0].videos[1].video = sportFiles[this.state.sport.node.frontmatter.title]?.prepareVid
    sport.node.frontmatter.sections[2].videos[1].video = shareVid
    sport.node.frontmatter.sections[0].videos[2].video = sportFiles[this.state.sport.node.frontmatter.title]?.analyzeVid
    sport.node.frontmatter.sections[2].videos[2].video = sportFiles[this.state.sport.node.frontmatter.title]?.feedbackVid
    // ----
    const headerImageName = sport.node.frontmatter.icon ? sport.node.frontmatter.icon.name : 'other';
    const headerImage = headerImgs.edges.find(i => i.node.name == headerImageName);
    const { pageContext } = this.props;
    const languageCode = pageContext && pageContext.lang ? pageContext.lang : "en";

    return (
      <Layout header={<Header num={1} translations={_} headerFullYOffset={600} mobileHeaderFullYOffset={40} pricingRoute={this.state.pricingRoute} fixed languageCode={languageCode} />} translations={_} languageCode={languageCode}>
        <Landing
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          hideOverlay={() => { this.setState({ showMessage: false }) }}
          hideLogo
          heading={_.sports_page.heading}
          subheading={_.sports_page.subheading}
          enter_your_email={_.enter_your_email}
          success_title={_.contact_message.success_title}
          success_subtitle={_.contact_message.success_subtitle}
          start_free_trial={_.start_free_trial}
          changeStyle={sportDetailLandingStyle}
          randomImg={headerImage}
          languageCode={languageCode} 
          actionButton={
            <a 
              href={getPdfPerSportAndLanguage(languageCode, this.state.sport.node.frontmatter.title.toLowerCase().replace(/\s+/g, "-"))}
              className={styles.actionButtonLink} target="_blank">
              <Button
                title={_.contact_message.success_title}
                subtitle={_.contact_message.success_subtitle}
                showMessage={showMessage}
                hideOverlay={() => {
                  this.setState({ showMessage: false })
                }}
                icon={pdfIcon}
                classStyle={styles.pdfButton}
              >
                {_.sports_page.download_pdf}
              </Button>
            </a>
          }
          scrollToId={'#video-title'}
        />
        <h2 className={styles.videoTitle} id="video-title">{_.sports_page.video_title}</h2>
        <CutContainer
          style={{position: "relative"}}
          sizeOfCut={2}
          styleClass={styles.cutContainer}
          topCut>
          <Context.Consumer>
            {data => {
              const landingVideo = !data.data.isMobile ? sport.node.frontmatter.landingVideoDesktop : sport.node.frontmatter.landingVideoMobile;
              return <div className={styles.videoContainer}>
                <video src={sportFiles[sport.node.frontmatter.title]?.video || multisportVid} controls={landingVideoClicked} className={styles.video} id="videoPlayer" alt="Video" poster={videoPoster} />
              </div>
            }}
          </Context.Consumer>
          <div style={{display: this.state.hideVideoPoster ? 'none' : 'block'}} className={[styles.videoContainer, styles.videoPoster, landingVideoClicked && styles.fadeout ].join(' ')} onClick={() => this.posterClick()} id="videoPoster">
            <img className={styles.videoPoster} src={videoPoster} style={{margin: 0}} />
          </div>
        </CutContainer>
        {sport.node.frontmatter.sections.map((val, index) => {
          return <TrackVisibility partialVisibility key={index}>
            {({ isVisible }) => { return <FeatureTMP key={index} isVisible={isVisible} dividerColor={styles.green} videos={val.videos} left={index % 2 === 0} texts={_.sports_page.sections[index]} /> }}
          </TrackVisibility>
        })}
        <div style={{display: sportFiles[this.state.sport.node.frontmatter.title]?.youtube ? 'inherit' : 'none'}}>
          <div className={[styles.contentDivider, styles.green].join(" ")} />
          <div
            className={[globalStyles.column, styles.greatnessContainer].join(
              " "
            )}
          >
            <h1 className={styles.contentHeader}>
              {_.sports_page.stories_title}
            </h1>
            <div className={styles.youtubeVideoWrapper}>
              <iframe
                src={
                  sportFiles[this.state.sport.node.frontmatter.title]?.youtube
                }
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
        <div className={[styles.contentDivider, styles.green].join(' ')} />
        <div className={[globalStyles.column, styles.greatnessContainer].join(' ')}>
          <h1 className={styles.contentHeader}>
            {_.sports_page.complete_solution}
          </h1>
          <Button onClick={openModal}>{_.try_xps.try_xps_for_free}</Button>
          <p className={styles.note}>{_.try_xps.no_credit_card}</p>
        </div>
        <CutContainer
          style={{
            background: grayGradient4,
            position: "relative"
          }}
          sizeOfCut={2}
          left
          topCut
          reverse
        >
          <div className={[globalStyles.column, styles.statisticSection].join(' ')}>
            <h4>
              {_.sport_detail.analyze.subheader}
            </h4>
            <h1 className={styles.contentHeader}>
              {_.sport_detail.analyze.header}
            </h1>
            <p>
              {_.sport_detail.analyze.paragraph}
            </p>
            <div style={{ width: "100%", maxWidth: 1024 }}>
              <Img fluid={statisticsImg.childImageSharp.fluid} />
            </div>
          </div>
        </CutContainer>

        <div className={[styles.testimonialsSection].join(' ')}>
          <h1>
            {_.sport_detail.teams.header}
          </h1>
          <div className={[reusedStyle.testimonials, styles.testimonials].join(' ')}>
            {testimonials.map((val, index) => {
              return <div key={index} className={reusedStyle.testimonial}>
                <Testimonial
                  location={val.location}
                  quote={val.quote}
                  avatar={val.image}
                  title={val.title.rendered}
                  subtitle={val.occupation}
                  video={val.video}
                />
              </div>
            })}
          </div>
        </div>
        <GetStarted translations={_}/>
        <Header num={2} translations={_} headerFullYOffset={600} pricingRoute={this.state.pricingRoute} className={globalStyles.hideOnMobile} languageCode={languageCode} />
        <Footer translations={_} languageCode={languageCode} />
      </Layout>
    )
  }
}

export default SportPage

export const query = graphql`
  query SportDetail($langFile: String){
    site {
      siteMetadata {
        ip_stack_key
      }
    }
    texts: file(relativePath: {eq: $langFile}) {
      _: childTextsYaml {
        ...HeaderTranslations
        ...FooterTranslations
        ...EmailTranslations
        ...TryXpsModalTranslations
        time_to_get_started
        start_free_trial
        ready_to_start
        enter_your_email
        sport_detail{
          analyze{
            subheader
            header
            paragraph
          }
          teams{
            header
          }
        }
        sports_page {
          heading
          subheading
          download_pdf
          video_title
          stories_title
          complete_solution
          sections {
            section_header
            texts {
              header
              paragraph
            }
          }
        }
        chat {
          full_name
          email
          message
          send_message
          success_message
          success_info
          success
          message_placeholder
        }
      }
    },
    testimonialImgs: allFile(filter: { relativePath: { glob: "testimonial/*" } }) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            fluid(maxWidth: 60, maxHeight: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    },
    headerImgs: allFile(filter: { relativePath: { glob: "sports/header/*" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1093) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    sportContent: allMarkdownRemark(filter: { fileAbsolutePath: { regex:"\/sportsContent/" } }) {
      edges {
        node {
          frontmatter {
            title
            icon {
              name
            }
            landingVideoDesktop
            landingVideoMobile
            sections {
              videos {
                video
              }
            }
            testimonials {
              testimonial
            }
            playbookImage {
              name
              childImageSharp {
                fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    },
    statisticsImg: file(relativePath: {eq: "sportDetail/statistics.webp" }) {
      childImageSharp {
        fluid(maxWidth: 2880) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    allWordpressTestimonials {
      edges {
        node {
          title: title { rendered }
          location: wpcf_location
          quote: wpcf_quote
          occupation: wpcf_position
          video: wpcf_url_video
          image: wpcf_profile_image
        }
      }
    }
  }
`