const pdfsMapPerLanguage = new Map<string, string[]>([
  ["en", ["basketball", "cricket", "fencing", "field-hockey", "floorball", "general", "handball", "ice-hockey", "rugby", "sports-school", "soccer", "volleyball", "water-polo"]],

  ["ar", ["basketball", "field-hockey", "floorball", "general", "handball", "ice-hockey", "soccer", "volleyball", "water-polo"]],
  ["cs", ["basketball", "general", "handball", "ice-hockey", "soccer"]],
  ["de", ["basketball", "field-hockey", "floorball", "general", "handball", "ice-hockey", "soccer", "volleyball", "water-polo"]],
  ["el", ["basketball",  "general",  "soccer"]],
  ["es", ["basketball", "field-hockey", "floorball", "general", "handball", "ice-hockey", "rugby", "soccer", "volleyball", "water-polo"]],
  ["fi", ["basketball", "floorball", "general", "handball", "ice-hockey",  "soccer", "volleyball", "water-polo"]],
  ["fr", ["basketball", "field-hockey", "floorball", "general", "handball", "ice-hockey",  "soccer", "volleyball", "water-polo"]],
  ["he", ["basketball", "field-hockey", "floorball", "handball", "ice-hockey",  "soccer", "volleyball", "water-polo"]],
  ["ja", ["basketball", "general", "handball", "ice-hockey",  "soccer"]],
  ["ms", ["basketball", "field-hockey", "floorball", "handball", "ice-hockey", "soccer", "volleyball", "water-polo"]],
  ["pl", ["basketball", "general", "handball", "ice-hockey", "soccer"]],
  ["pt", ["soccer"]],
  ["ru", ["field-hockey", "floorball", "general", "handball", "ice-hockey", "soccer", ]],
  ["zh", ["basketball", "field-hockey", "floorball", "general", "handball", "ice-hockey",  "soccer", "volleyball", "water-polo"]],
])

export const getPdfPerSportAndLanguage = (language: string, sport: string) => {
  const pdfsPerLanguage = pdfsMapPerLanguage.get(language)

  if (pdfsPerLanguage?.length) {
    const hasSportSpecificPdf = pdfsPerLanguage.includes(sport)
    const hasGeneralPdf = pdfsPerLanguage.includes("general")

    if (!hasGeneralPdf && !hasSportSpecificPdf) {
      return getPdfPerSportAndLanguage("en", sport)
    }

    const pdfName = hasSportSpecificPdf ? sport : "general"
    
    return `/overview/${language}/${pdfName}.pdf`
  } else {
    return getPdfPerSportAndLanguage("en", sport)
  }
}
